import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "./core/core.module";
import {HttpClientModule} from "@angular/common/http";
import {TransferHttpCacheModule} from "@nguniversal/common";
import {NbLayoutModule} from "@nebular/theme";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NebularModule} from './shared/nebular-components/nebular.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NebularModule,
    TransferHttpCacheModule,
    NbLayoutModule,
    CoreModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
