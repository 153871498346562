import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./footer/footer.component";
import {GlobalSidebarComponent} from "./global-sidebar/global-sidebar.component";
import {HeaderComponent} from "./header/header.component";
import {
    NbActionsModule,
    NbButtonModule, NbCardModule,
    NbContextMenuModule,
    NbIconModule,
    NbListModule, NbMenuModule, NbPopoverModule, NbTooltipModule,
    NbUserModule
} from "@nebular/theme";
import {RouterModule} from "@angular/router";
import {NbSecurityModule} from "@nebular/security";


@NgModule({
  declarations: [FooterComponent, GlobalSidebarComponent, HeaderComponent],
  exports: [
    HeaderComponent,
    GlobalSidebarComponent,
    FooterComponent
  ],
    imports: [
        CommonModule,
        NbUserModule,
        NbIconModule,
        NbContextMenuModule,
        NbButtonModule,
        NbListModule,
        RouterModule,
        NbActionsModule,
        NbPopoverModule,
        NbCardModule,
        NbTooltipModule,
        NbSecurityModule,
        NbMenuModule
    ]
})
export class LayoutComponentsModule {
}
