import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {CookieModule} from "../shared/cookie/browser/cookie.module";
import {NgxWebstorageModule} from "ngx-webstorage";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient} from "@angular/common/http";
import {TransferState} from "@angular/platform-browser";
import {translateBrowserLoaderFactory} from "./translate/translate-browser.loader";
import {NbTokenStorage} from "@nebular/auth";
import {NbRoleProvider} from "@nebular/security";
import {LayoutService} from "./util/layout.service";
import {TokenCookieStorageService} from "./util/token-cookie-storage.service";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {ErrorHandlerInterceptor} from "./interceptors/error-handler.interceptor";
import {RoleProvider} from "./util/role-provider";
import {NebularInitialModule} from "./nebular-initial.module";
import {LayoutModule} from "../shared/layout/layout.module";
import {registerLocaleData} from "@angular/common";
import localePt from '@angular/common/locales/pt';
import {NgxMaskModule} from "ngx-mask";
import {MarkdownModule} from "ngx-markdown";
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [],
  exports: [
    CookieModule,
    NgxWebstorageModule
  ],
  imports: [
    NebularInitialModule,
    LayoutModule,
    TranslateModule.forRoot(
      {
        defaultLanguage: 'pt',
        loader: {
          provide: TranslateLoader,
          useFactory: translateBrowserLoaderFactory,
          deps: [HttpClient, TransferState]
        },
        extend: true
      }
    ),
    CookieModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    NgxMaskModule.forRoot(),
    MarkdownModule.forRoot()
  ],
  providers: [
    LayoutService,
    {provide: NbTokenStorage, useClass: TokenCookieStorageService},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    {
      provide: LOCALE_ID, useValue: 'pt-BR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: NbRoleProvider, useClass: RoleProvider,
    },
  ],
})
export class CoreModule {
}
