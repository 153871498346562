import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {commonEnvironment} from "../../../../../environments/common-environment";
import {DATE_TIME_FORMAT_BAR_COMPLETE} from "../../../../constants/input.constants";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  lastBuildTime: string;

  constructor() {
    this.lastBuildTime = '---';
    this.handleLastBuildTime();
  }

  ngOnInit(): void {
  }

  handleLastBuildTime(): void {
    const buildTime = commonEnvironment.LAST_BUILD_TIME.split('###')[1] ?? null;
    const momentBuildTime = moment(new Date(buildTime));
    this.lastBuildTime = momentBuildTime.format(DATE_TIME_FORMAT_BAR_COMPLETE);
  }
}
