<nb-layout>

  <nb-layout-header fixed>
    <!-- Insert header here -->
    <app-header class="container"></app-header>
    <div class="app-dev-warning" *ngIf="!isProd">
      <nb-tag text="Versão de desenvolvimento" appearance="outline" status="warning" size="small" class="text-nowrap"></nb-tag>
    </div>
  </nb-layout-header>
  <nb-layout-header subheader class="subheader d-none d-lg-block breadcrumb" *ngIf="subheader">
    <!-- <div class="breadcrumb_area">
      <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs">
          <span routerLinkActive="router-link-active">
            {{ breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1) }}
            <nb-icon icon="chevron-right"></nb-icon>
          </span>
        </li>
      </ol>
    </div> -->
    <xng-breadcrumb style="width: 100%; padding-left: 3%;" [separator]="iconTemplate">
      <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
        <nb-icon *ngIf="info" style="padding-right: 5%;" [icon]="info"></nb-icon>
        <ng-container class="breadcrumb-item">{{ breadcrumb | titlecase }}</ng-container>
      </ng-container>
    </xng-breadcrumb>
    <ng-template #iconTemplate>
      <nb-icon style="font-size: 15px;" icon="arrow-ios-forward-outline"></nb-icon>
    </ng-template>
  </nb-layout-header>

  <nb-sidebar fixed responsive tag="global-sidebar"
              [collapsedBreakpoints]="['xs', 'is', 'sm','md','lg','xl','xxl','xxxl']" [compactedBreakpoints]="[]"
              state="collapsed">
    <app-global-sidebar></app-global-sidebar>
  </nb-sidebar>

  <nb-layout-column>

    <router-outlet></router-outlet>

  </nb-layout-column>
  <nb-layout-footer>
    <app-footer></app-footer>
  </nb-layout-footer>
</nb-layout>
