export const accessPermissions = {
  admin: {
    view: '*',
    create: '*',
    delete: '*'
  },
  student: {
    view: ['dashboard', 'user-result-module-classroom', 'my_submission'],
    create: [],
    delete: []
  },
  teacher: {
    view: ['dashboard','users-results', 'all_submission','manager'],
    create: ['module'],
    delete: []
  },
  guest: {
    view: [],
    create: [],
    delete: []
  }
};
