import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';
import {Authority} from "../../../../constants/authority.constants";
import {IJudgeHostBasic} from "../../../../models/basic/judgehost-basic.model";
import {themeItems, userMenu} from "../../../../constants/admin-header.constants";
import {ThemesEnum} from "../../../../constants/themes.enum";
import {IAccount} from "../../../../models/user/account.model";
import {UserHeaderConstants} from "../../../../constants/user-header.constants";
import {AuthService} from "../../../../services/auth.service";
import {AccountService} from "../../../../services/account.service";
import {SessionStorageService} from "ngx-webstorage";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LayoutService} from "../../../../core/util/layout.service";
import {JudgeHostService} from "../../../../services/judgehost.service";
import {filter, map} from "rxjs/operators";
import {ThemeHandleService} from "../../../../services/theme/theme-handle.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  authorities: Authority[] | undefined;
  @ViewChild('teste') input: any;
  themes: NbMenuItem[] | any = themeItems;
  judgeHosts: IJudgeHostBasic[] | undefined;
  languagesAction = [
    {title: 'Loading'}
  ];
  userPictureOnly = false;
  currentTheme: ThemesEnum = ThemesEnum.DARK;
  userMenu = userMenu;
  account: IAccount | undefined;
  userHeader = UserHeaderConstants;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              public authService: AuthService,
              public accountService: AccountService,
              private sessionStorage: SessionStorageService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private translateService: TranslateService,
              private dialogService: NbDialogService,
              private layoutService: LayoutService,
              private judgeHostService: JudgeHostService,
              private themeHandle:ThemeHandleService
  ) {
  }

  ngOnInit(): void {
    this.accountService.accountSubject.subscribe(account => {
      this.account = account;
    });
    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'languageMenu'),
        map((item: any) => item),
      )
      .subscribe(({item}) => {
        if (item.initials) {
          this.translateService.use(item.initials);
          this.getTranslationActions();
        }
      });
    this.menuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'userMenu'),
        map(({item}) => item),
      )
      .subscribe((menu: any) => {
        if (menu.id === 0) {
          this.router.navigate(['/account/profile']).then(() => {
            this.sidebarService.collapse('global-sidebar');
          });
        }
        if (menu.id === 1) {
          this.authService.logout();
          this.sidebarService.collapse('global-sidebar');
        }
      });

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName.name;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getTranslationActions(): void {
    this.translateService.get('languages').pipe(
      takeUntil(this.destroy$),
    ).subscribe((languagesT: any[]) => {
      this.languagesAction = [];
      if (languagesT && languagesT?.length > 0) {
        languagesT?.forEach((language, i) => {
          // @ts-ignore
          this.languagesAction.push({title: language.name, initials: language.initials, id: i});
        });
      }
    });
  }

  getJudgeHosts(): void {
    if (this.accountService.account?.isAdmin()) {
      this.judgeHostService.query({size: 1000}, true).pipe(
        takeUntil(this.destroy$),
      ).subscribe((judgeHosts) => {
        this.judgeHosts = judgeHosts.data;
      });
    }
  }


  toggleSidebar(compact = true): boolean {
    this.sidebarService.toggle(compact, 'global-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }


  isAnyJudgeHostActive(): boolean {
    if (this.judgeHosts && this.judgeHosts?.length > 0) {
      return this.judgeHosts.some((judgeHost) => {
        return judgeHost?.active;
      });
    }
    return false;
  }

  toggleTheme(): void {
    if (this.currentTheme === ThemesEnum.LIGHT) {
      this.themeHandle.changeTheme(ThemesEnum.DARK);
    } else {
      this.themeHandle.changeTheme(ThemesEnum.LIGHT);
    }
  }
}
