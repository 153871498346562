import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieMiddlewareService} from "../../shared/cookie/cookie-middleware.service";
import {themes} from "../../constants/themes.constants";
import {isPlatformBrowser} from "@angular/common";
import {NbThemeService} from "@nebular/theme";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ThemeHandleService {
  isBrowser = false;

  constructor(
    private cookieService: CookieMiddlewareService,
    private themeService: NbThemeService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  setTheme(): void {
    const allThemes = themes;
    const currentTheme = this.cookieService.getCookie('theme');
    const themeMatched = allThemes.some((theme: any) => {
      return theme?.toLowerCase() === currentTheme?.toLowerCase();
    });
    if (themeMatched) {
      this.changeTheme(currentTheme);
    } else if (this.isBrowser) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // dark mode
        this.changeTheme('dark');
      } else {
        this.changeTheme('default');
      }
    }
  }

  changeTheme(themeName: string): void {
    this.themeService.changeTheme(themeName);
    const date = new Date(moment().add(12, 'months').format('YYYY-MM-DD'));
    this.cookieService.putCookie('theme', themeName, date);
    this.changeHighLightjs(themeName);
  }

  changeHighLightjs(themeName: string): void {
    if (this.isBrowser) {
      themes.forEach((theme) => {
        if (theme === themeName) {
          document.querySelector(`link[title="${theme}"]`)!.removeAttribute('disabled');
        } else {
          document.querySelector(`link[title="${theme}"]`)!.setAttribute('disabled', 'disabled');
        }
      });
    }
  }
}
