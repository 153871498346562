import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ThemeHandleService} from "../../../services/theme/theme-handle.service";
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-one-column-layout',
  templateUrl: './one-column-layout.component.html',
  styleUrls: ['./one-column-layout.component.scss']
})
export class OneColumnLayoutComponent implements OnInit {


  isProd: boolean;
  subheader = true;

  constructor(
    private themeHandle:ThemeHandleService,
    private breadcrumbService: BreadcrumbService
  ) {

    this.isProd = environment.production;
    this.themeHandle.setTheme();
  }

  ngOnInit(): void {
    this.breadcrumbService.set('admin', 'Dashboard')
  }

}
