
import {Moment} from 'moment';
import {DATE_TIME_FORMAT_BAR} from '../../constants/input.constants';
import * as moment from 'moment';

export interface IJudgeHostBasic {
  id?: number | null;
  description?: string;
  category?: string;
  active?: boolean;
  polltime?: Moment;
  polltimeDisplayed?: string;
  createdDate?: Moment;
  createdDateDisplayed?: string;
  lastModifiedDate?: Moment;
  lastModifiedDateDisplayed?: string;
  // restriction?: IJudgehostRestriction;
}

export class JudgeHostBasic implements IJudgeHostBasic {
  id?: number | null;
  description?: string;
  category?: string;
  public active?: boolean;
  public polltime?: Moment;
  public polltimeDisplayed?: string;
  public createdDate?: Moment;
  public createdDateDisplayed?: string;
  public lastModifiedDate?: Moment;
  public lastModifiedDateDisplayed?: string;

  constructor(judgehost?: IJudgeHostBasic) {
    if (judgehost) {
      this.id = judgehost?.id;
      this.description = judgehost?.description;
      this.category = judgehost?.category;
      this.polltime = moment(judgehost?.polltime);
      this.polltimeDisplayed = this.polltime.format(DATE_TIME_FORMAT_BAR);
      this.createdDate = moment(judgehost?.createdDate);
      this.createdDateDisplayed = this.createdDate.format(DATE_TIME_FORMAT_BAR);
      this.lastModifiedDate = moment(judgehost?.lastModifiedDate);
      this.lastModifiedDateDisplayed = this.lastModifiedDate.format(DATE_TIME_FORMAT_BAR);
      this.active = this.active || false;
    }
  }
}

export const JudgehostBasicTableColumn = [

  {
    title: 'Código',
    class: 'text-md-left'
  },
  {
    title: 'Hostname',
    class: 'text-md-left'
  },
  {
    title: 'Active',
    class: 'text-md-left'
  },
  {
    title: 'Polltime',
    class: 'text-md-left'
  },
  {
    title: 'Created Date',
    class: 'text-md-left'
  },
  {
    title: 'Last Modified Date',
    class: 'text-md-left'
  },
  {
    title: '',
    class: 'text-md-left'
  }
];
