import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {AccountService} from "../../services/account.service";
import {AuthService} from "../../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router,
    private authService: AuthService,
    private nbAuthService: NbAuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.nbAuthService.isAuthenticated()
      .pipe(mergeMap((authenticated) => {
          if (authenticated) {
            return this.accountService.getAccount(false).pipe(
              map((account) => {
                if (account && account?.authorities) {
                  if (account.hasAnyAuthority(route.data['authorities'])) {
                    return true;
                  } else {
                    this.router.navigateByUrl('/404');
                    return false;
                  }
                } else {
                  this.router.navigateByUrl('/404');
                  return false;
                }
              }),
              catchError((err) => {
                if (!err || err?.status === 404 || err?.status === 0) {
                  this.router.navigateByUrl('OFFLINESERVER');
                  return of(false);
                } else if (err?.status === 401) {
                  this.authService.logout();
                  this.router.navigateByUrl('/');
                  return of(false);
                } else {
                  return of(true);
                }
              }));
          } else {
            this.authService.logout();
            this.router.navigate(['auth/login']);
            return of(false);
          }
        })
      );
  }
}
