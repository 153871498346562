import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OneColumnLayoutComponent} from "./one-column-layout/one-column-layout.component";
import {NbIconModule, NbLayoutModule, NbSidebarModule, NbTagModule} from "@nebular/theme";
import {RouterModule} from "@angular/router";
import {LayoutComponentsModule} from "./layout-components/layout-components.module";
import {BreadcrumbModule} from 'xng-breadcrumb';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [OneColumnLayoutComponent],
  imports: [
    CommonModule,
    NbTagModule,
    NbSidebarModule,
    NbLayoutModule,
    BreadcrumbModule,
    FontAwesomeModule,
    NbIconModule,
    RouterModule,
    LayoutComponentsModule
  ]
})
export class LayoutModule { }
