import {Authority} from '../../constants/authority.constants';
import {environment} from '../../../environments/environment';

export interface IAccount {
  id?: number | null;
  activated?: boolean | null;
  authorities: Authority[];
  email?: string | null;
  firstName?: string | null;
  langKey?: string | null;
  lastName?: string | null;
  login?: string | null;
  imageUrl?: string | null;

  isTeacher(): boolean;

  isAdmin(): boolean;

  isStudent(): boolean;

  hasAnyAuthority(authorities: Authority[]): boolean;
}

export class Account {
  id?: number | null;
  activated?: boolean | null;
  authorities: Authority[];
  email?: string | null;
  firstName?: string | null;
  langKey?: string | null;
  lastName?: string | null;
  login?: string | null;
  imageUrl?: string | null;

  constructor(account?: IAccount) {
    this.authorities = [];
    if (account) {
      this.id = account.id;
      this.authorities = account.authorities;
      this.email = account.email;
      this.login = account.login;
      this.imageUrl = environment.IMAGES_URL + account.imageUrl;
      this.activated = account.activated;
      this.langKey = account.langKey;
      this.lastName = account.lastName;
      this.firstName = account.firstName;
    }
  }

  // get getImageUrl(): string {
  //   return `${SERVER_API_IMAGE_URL}${this._imageUrl}`;
  // }

  // getImageUrl(): string {
  //   return `${SERVER_API_IMAGE_URL}${this.imageUrl}`;
  // }

  isTeacher(): boolean {
    if (this) {
      return this.authorities!.includes(Authority.TEACHER);
    }
    return false;
  }

  isAdmin(): boolean {
    if (this) {
      return this.authorities!.includes(Authority.ADMIN);
    }
    return false;
  }

  isStudent(): boolean {
    if (this) {
      return this.authorities!.includes(Authority.USER);
    }
    return false;
  }

  hasAnyAuthority(authorities: Authority[]): boolean {
    if (this.authorities && this.authorities?.length > 0) {
      return this.authorities.some((authority) => {
        return authorities.includes(authority);
      });
    }
    return false;
  }
}
