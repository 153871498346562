import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {AccountService} from "../../services/account.service";
import {CookieMiddlewareService} from "../../shared/cookie/cookie-middleware.service";
import {AuthService} from "../../services/auth.service";


@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router,
    private cookieService: CookieMiddlewareService,
    private authService: AuthService,
    private nbAuthService: NbAuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.nbAuthService.isAuthenticated()
      .pipe(map((authenticated) => {
          if (authenticated) {
            this.router.navigateByUrl(`/dashboard`);
            return false;
          } else {
            return true;
          }
        }),
        catchError(() => {
          return of(true);
        }));
    // return this.accountService.getAccount().pipes(
    //   map((account) => {
    //     if (account && account.id) {
    //       this.router.navigateByUrl(`/dashboard`);
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }),
    //   catchError(() => {
    //     return of(true);
    //   }));
  }
}
