import {IUserHeader} from '../models/user-header.model';

export const UserHeaderConstants: IUserHeader[] = [
  {
    title: 'Painel de controle',
    link: '/dashboard',
    auth: true,
    permission: 'view',
    resource: 'dashboard',
    icon: 'home-outline'
  },
  {
    title: 'Submissões',
    link: '/submission/dashboard',
    auth: true,
    permission: 'view',
    resource: 'all_submission',
    icon: 'home-outline'
  },
  {
    title: 'Minhas submissões',
    link: '/account/submission/dashboard',
    auth: true,
    permission: 'view',
    resource: 'my_submission',
    icon: 'home-outline'
  },
  {
    title: 'Disciplinas',
    auth: true,
    permission: 'view',
    resource: 'registers',
    icon: 'home-outline',
    children: [
      {
        title: 'Disciplinas',
        link: '/admin/discipline/'
      },
      {
        title: 'Tópicos',
        link: '/admin/topic/'
      },
      {
        title: 'Exercícios',
        link: '/admin/exercise/'
      },
      {
        title: 'Skills',
        link: '/admin/skill/'
      },
    ]
  },
  {
    title: 'Cursos',
    auth: true,
    permission: 'view',
    resource: 'registers',
    icon: 'home-outline',
    children: [
      {
        title: 'Cursos',
        link: '/admin/course'
      },
      {
        title: 'Tópicos do módulo',
        link: '/admin/module/topic/'
      },
      {
        title: 'Exercícios do módulo',
        link: '/admin/module/topic/exercise'
      },
    ]
  },
  {
    title: 'Configurações Gerais',
    link: '/admin/general-config',
    auth: true,
    permission: 'view',
    resource: 'my_submission',
    icon: 'home-outline'
  },
  {
    title: 'Entrar',
    link: '/',
    auth: false,
    icon: 'arrow-forward-outline'
  },
  {
    title: 'Resgitrar',
    link: '/register',
    auth: false,
    icon: 'person-add-outline'
  }
];
