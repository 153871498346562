import {Injectable} from '@angular/core';
import {NbRoleProvider} from '@nebular/security';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountService} from '../../services/account.service';
import {IAccount} from '../../models/user/account.model';
import {HandlePermissionService} from '../../services/handle-permission.service';
import {CookieMiddlewareService} from "../../shared/cookie/cookie-middleware.service";


@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(
    private accountService: AccountService,
    private cookieService: CookieMiddlewareService,
    private handlePermission: HandlePermissionService
  ) {
  }

  getRole(): Observable<string | string[]> {
    return this.accountService.accountSubject.pipe(map(account => {
      if (account) {
        const modifiedAccount: IAccount = this.handlePermission.handleAuthority(account);
        const permissions: string[] = [];
        if (modifiedAccount?.isAdmin()) {
          permissions.push('admin');
        }
        if (modifiedAccount?.isStudent()) {
          permissions.push('student');
        }
        if (modifiedAccount?.isTeacher()) {
          permissions.push('teacher');
        }
        return permissions;
      }
      return 'guest';
    }));
  }
}
