import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timeout} from 'rxjs';
import {Account, IAccount} from '../models/user/account.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {IProfile} from '../models/user/profile.model';
import {IAboutMe} from '../models/user/about-me.model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  account$: Observable<IAccount | null> | null;
  accountSubject: BehaviorSubject<IAccount | null>;
  account: IAccount | null;

  constructor(
    private http: HttpClient
  ) {
    this.accountSubject = new BehaviorSubject<IAccount | null>(null);
    this.account = null;
    this.account$ = null;
  }

  getAccount(force?: boolean) {
    if (force || !this.account$) {
      this.account$ = this.getAccountFromApi(force).pipe(timeout(10000),map((account: IAccount) => {
        this.account = new Account(account);
        this.accountSubject.next(this.account);
        return this.account;
      }), shareReplay(1));
    }
    return this.account$;
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (this.account && this.account.authorities) {
      return this.account.authorities.some((authority: string) =>
        authorities.includes(authority)
      );
    } else {
      return false;
    }
  }

  getAccountFromApi(force?: boolean): Observable<IAccount> {
    const forceS = force ? force.toString() : 'false';
    const options = {headers: new HttpHeaders({force: forceS})};
    return this.http.get<IAccount>(`${environment.API_URL}account`, {...options, responseType: 'json'});
  }

  save(account: IAccount): Observable<{}> {
    return this.http.post(`${environment.API_URL}account`, account);
  }

  imageUpload(image: File): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);
    return this.http.put<boolean>(`${environment.API_URL}account/users/image`, formData);
    // .pipes(map(() => { return true; }));
  }

  saveprofile(profile: IProfile) {
    const url = `account/profile/`;
    return this.http.put<boolean>(`${environment.API_URL}${url}`, profile);
  }

  saveAboutMe(aboutMe: IAboutMe): Observable<any> {
    const url = `account/profiles`;
    return this.http.put<boolean>(`${environment.API_URL}${url}`, aboutMe);
  }
}
