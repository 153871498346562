import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {CookieService} from './browser/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class CookieMiddlewareService {

  constructor(
    private cookieService: CookieService,
  ) {
  }


  putCookie(key: string, value: any, date: any = null): void {
    if (!date) {
      date = new Date(moment().add(5, 'years').format('YYYY-MM-DD'));
    }
    this.cookieService.put(key, value, {
      expires: date,
      // domain: this.env.getEnvironment.DOMAIN,
      sameSite: 'none',
      secure: true,
    });
  }

  putCookieObject(key: string, value: any, date: any = null): void {
    if (!date) {
      date = new Date(moment().add(5, 'years').format('YYYY-MM-DD'));
    }
    this.cookieService.putObject(key, value, {
      expires: date,
      // domain: this.env.getEnvironment.DOMAIN,
      sameSite: 'none',
      secure: true,
    });
  }

  removeCookie(key: string): void {
    this.cookieService.remove(key, {
      // domain: this.env.getEnvironment.DOMAIN,
      sameSite: 'none',
      secure: true
    });
  }

  getCookie(key: string): any {
    return this.cookieService.get(key);
  }

  getCookieObject(key: string): any {
    return this.cookieService.getObject(key);
  }
}
