<div class="d-flex justify-content-between align-items-center flex-row">
  <div class="d-flex align-items-center">
    <a class="logo-container" [routerLink]="['','dashboard']">
      <img alt="logo-wok" width="80" src="../../../../assets/images/logo-wok.png">
    </a>
    <div class="ms-5 d-none d-lg-flex">
      <ng-container *ngFor="let menu of userHeader">
        <ng-template #menuItemWithUrl>
          <a class="header-link"
             [routerLink]="[menu.link]">{{menu.title}}</a>
        </ng-template>
        <ng-template #menuItemWithChildren>
          <a class="header-link" [nbContextMenu]="menu.children">{{menu.title}}</a>
        </ng-template>


        <ng-container *ngIf="(menu.auth && account) && menu?.permission && menu?.resource">
          <ng-container *nbIsGranted="[menu.permission??'',menu.resource??'']">
            <ng-container *ngIf="menu?.link && !menu?.children;else menuItemWithChildren"
                          [ngTemplateOutlet]="menuItemWithUrl"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!menu?.auth && !account">
          <ng-container *ngIf="menu?.link && !menu?.children;else menuItemWithChildren"
                        [ngTemplateOutlet]="menuItemWithUrl"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div>
    <div class="d-lg-none">
      <button nbButton appearance="ghost" status="basic" size="large" (click)="toggleSidebar(true)">
        <nb-icon icon="menu-outline" style="font-size: 150px !important"></nb-icon>
      </button>
    </div>
    <nb-actions size="small" class="d-none d-lg-flex">
      <nb-action class="control-item" icon="clipboard-outline"
                 *ngIf="(accountService?.account && accountService.account!.isAdmin())" (click)="getJudgeHosts()"
                 [nbPopover]="list" badgeText="2"
                 [badgeStatus]="isAnyJudgeHostActive()?'success':'danger'"
                 [badgeDot]="true"
                 badgePosition="top right">
      </nb-action>

      <nb-action class="control-item" icon="sun-outline" (click)="toggleTheme()"></nb-action>

      <nb-action class="user-action" *ngIf="accountService?.account">
        <nb-user [nbContextMenu]="userMenu"
                 [onlyPicture]="true"
                 [name]="accountService?.account?.login??'---'"
                 [title]="accountService?.account?.email??'---'"
                 [picture]="accountService?.account?.imageUrl??'---'"
                 nbContextMenuTag="userMenu">
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>
<ng-template #list>
  <nb-card class="mb-0 border-0" style="width: 300px">
    <nb-card-header>
      <h6 class="fw-bold poppins">Julgadores</h6>
    </nb-card-header>
    <nb-list>
      <nb-list-item class="d-flex align-items-center" *ngFor="let judgeHost of judgeHosts">
        <nb-icon class="me-2" style="font-size: 30px" [status]="judgeHost.active?'success':'danger'"
                 [icon]="judgeHost.active?'checkmark-outline':'close-outline'"></nb-icon>
        <div class="d-flex flex-column">
          <span class="">{{judgeHost.id}}</span>
          <small class="fw-bold gray-color" *ngIf="judgeHost?.polltimeDisplayed"
                 nbTooltip="Ultima atuação em {{judgeHost.polltimeDisplayed}}">{{judgeHost.polltimeDisplayed}}</small>
        </div>
      </nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>
