<div class="mb-4 d-flex flex-column justify-content-center">
  <div class="hr" *ngIf="accountService.account">
    <nb-user size="giant" name="teste" title="teste 2" nbContextMenuTag="userMenu" [nbContextMenu]="userMenu"></nb-user>
  </div>
  <div class="text-center">
    <button nbButton appearance="ghost" (click)="toggleTheme()">
      <nb-icon icon="sun-outline"></nb-icon>
    </button>
  </div>
</div>
<nb-menu [items]="filteredEntitiesList">
</nb-menu>
<!--<nb-list>-->
<!--  <nb-list-item *ngFor="let menu of filteredEntitiesList;trackBy:trackBy" (click)="goToUrl(menu)">-->
<!--    <a [routerLink]="menu.link" class="header-link">-->
<!--      <div class="d-flex align-items-center">-->
<!--        <nb-icon *ngIf="menu?.icon" [icon]="menu.icon??''" class="me-3"></nb-icon>-->
<!--        <span class="fw-bold">{{menu?.title}}</span>-->
<!--      </div>-->
<!--    </a>-->
<!--  </nb-list-item>-->
<!--</nb-list>-->
