import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IUserAuth} from '../models/user/UserLogin.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable, of} from 'rxjs';
import {AccountService} from './account.service';
import {Account} from '../models/user/account.model';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';
import {CookieMiddlewareService} from "../shared/cookie/cookie-middleware.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cookieService: CookieMiddlewareService,
    private accountService: AccountService,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  login(user: IUserAuth, rememberMe = false): Observable<Account | null> {
    return this.loginMiddleware(user, rememberMe).pipe(switchMap((result: boolean) => {
      if (result) {
        return this.accountService.getAccount(true);
      } else {
        return of(null);
      }
    }));
  }

  loginMiddleware(user: IUserAuth, rememberMe = false): Observable<boolean> {
    return this.http.post<any>(`${environment.API_URL}authenticate`, user).pipe(
      catchError(() => {
        return of(false);
      }),
      map((obj: any) => {
        const date = rememberMe ? new Date(moment().add(1, 'months').format('YYYY-MM-DD')) : null;
        this.cookieService.putCookie('id_token', obj?.id_token, date);
        return true;
      }));
  }

  register(user: IUserAuth): Observable<boolean> {
    return this.http.post<boolean>(`${environment.API_URL}register`, user);
  }

  logout(): void {
    if (this.isBrowser) {
      this.router.navigateByUrl('/');
      this.cookieService.removeCookie(environment.AUTH_COOKIE);
      this.cookieService.removeCookie('access-permission');
      this.localStorageService.clear('url_back');
      this.accountService.account = null;
      this.accountService.account$ = null;
      this.accountService.accountSubject.next(null);
    }
  }

  // getUserByGoogleToken(userToken: NbAuthToken): void {
  //   this.http.get(`https://www.googleapis.com/plus/v1/people/me?access_token=${userToken.getValue()}`).subscribe((user) => {
  //   });
  //   // this.http.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {headers: {Authorization: 'Bearer ' + userToken.getValue()}}).subscribe((user) => {
  //   //   console.warn(user);
  //   // });
  // }

  requestPassword(mail: string): Observable<{}> {
    return this.http.post(environment.API_URL + 'account/reset-password/init', mail);
  }

  newPassword(key: string, newPassword: string): Observable<{}> {
    return this.http.post(environment.API_URL + 'account/reset-password/finish', {key, newPassword});
  }
}
