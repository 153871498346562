import {Injectable} from '@angular/core';
import {Account, IAccount} from '../models/user/account.model';
import {AccountService} from './account.service';
import {Authority} from '../constants/authority.constants';
import {CookieMiddlewareService} from "../shared/cookie/cookie-middleware.service";

@Injectable({
  providedIn: 'root'
})
export class HandlePermissionService {
  account: IAccount | undefined;

  constructor(
    private accountService: AccountService,
    private cookieService: CookieMiddlewareService
  ) {
    this.accountService.accountSubject.subscribe(account => {
      this.account = account;
    });
  }


  getApiRole(noHasAdmin: boolean = false): string {
    if (this.account) {
      const modifiedAccount: IAccount = this.handleAuthority(this.account);
      if (modifiedAccount?.isAdmin() && !noHasAdmin) {
        return 'admin';
      } else if (modifiedAccount?.isAdmin()) {
        return 'teacher';
      }
      if (modifiedAccount?.isTeacher()) {
        return 'teacher';
      }
      if (modifiedAccount?.isStudent()) {
        return 'account';
      }
    }
    return 'unset';
  }

  handleAuthority(account: IAccount): any {
    const newAccount = new Account(account);
    let authorities = this.getCurrentAuthority();
    if (authorities) {
      newAccount.authorities = [authorities];
    }
    return newAccount;
  }

  getCurrentAuthority(): any {
    return this.cookieService.getCookie('access-permission') ?? this.getHigherAuthority();
  }

  setCurrentAuthority(authorities: Authority): void {
    this.cookieService.putCookie('access-permission', authorities);
  }

  getHigherAuthority(): Authority | undefined {
    if (this.account) {
      if (this.account?.isAdmin()) {
        return Authority.ADMIN;
      }
      if (this.account?.isTeacher()) {
        return Authority.TEACHER;
      }
      if (this.account?.isStudent()) {
        return Authority.USER;
      }
    }
    return undefined;
  }
}
