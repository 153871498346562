import {NgModule} from '@angular/core';
import {
  NbDatepickerModule,
  NbDialogModule, NbLayoutModule,
  NbMenuModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule, NbWindowModule
} from "@nebular/theme";
import {NbSecurityModule} from "@nebular/security";
import {accessPermissions} from "./access-permisions";
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from "@nebular/auth";
import {environment} from "../../environments/environment";
import {NbEvaIconsModule} from "@nebular/eva-icons";


@NgModule({
  declarations: [],
  imports: [
    NbThemeModule.forRoot({name: 'default'}),
    NbSecurityModule.forRoot({
      accessControl: accessPermissions
    }),
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbWindowModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot({destroyByClick: true, preventDuplicates: true}),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'main',
          baseEndpoint: environment.API_URL,
          token: {
            class: NbAuthJWTToken,

            key: 'id_token'
          },
          login: {
            // ...
            endpoint: 'authenticate',
            method: 'post',
            redirect: {
              success: '/dashboard', // welcome page path
              failure: null, // stay on the same page
            },
          },
          register: {
            // ...
            endpoint: 'register',
            method: 'post',
            redirect: {
              success: '/?justRegister=true', // welcome page path
              failure: null, // stay on the same page
            },
          },
          requestPass: {
            endpoint: '/request-pass',
          },
          resetPass: {
            endpoint: '/reset-pass',
          },
        }),
      ],
      forms: {
        login: {
          redirectDelay: 0,
          showMessages: {     // show/not show success/error messages
            success: true,
            error: true,
          },
        }
      },
    }),
    NbSelectModule
  ]
})
export class NebularInitialModule { }
