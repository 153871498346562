export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  TEACHER = 'ROLE_TEACHER',
  USER = 'ROLE_USER',
  IMPORTED = 'ROLE_IMPORTED',
  AUTHOR = 'ROLE_AUTHOR',
  SYSTEM = 'ROLE_SYSTEM'
}
export const all_authorities:string[] = [
  Authority.ADMIN,
  Authority.TEACHER,
  Authority.USER,
  Authority.IMPORTED,
  Authority.AUTHOR,
  Authority.SYSTEM
]
