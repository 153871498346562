import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IJudgeHostBasic, JudgeHostBasic} from '../models/basic/judgehost-basic.model';
import {Authority} from '../constants/authority.constants';
import {map} from 'rxjs/operators';
import {createRequestOption} from '../core/util/request-util';
import {environment} from '../../environments/environment';
import {HandlePermissionService} from './handle-permission.service';

@Injectable({
  providedIn: 'root'
})
export class JudgeHostService {


  constructor(
    private http: HttpClient,
    private handleService: HandlePermissionService
  ) {
  }

  query(req?: any, force = false): Observable<{ headers: HttpHeaders, data: IJudgeHostBasic[] }> {
    const url = `admin/judgehosts`;
    const options = createRequestOption(req);
    const headers = new HttpHeaders({force: String(force)});
    return this.http
      .get<IJudgeHostBasic[]>(`${environment.API_URL}${url}`, {
        params: options,
        headers,
        observe: 'response',
        responseType: 'json'
      }).pipe(map((data) => {
        const judgehosts: IJudgeHostBasic[] = data.body!.map((judgehost) => {
          return new JudgeHostBasic(judgehost);
        });
        return {headers: data.headers, data: judgehosts};
      }));
  }


  // getJudging(submissionId:number): Observable<IJudgeHostBasic>{
  //     return();
  // }


  update(judgehost: IJudgeHostBasic): Observable<IJudgeHostBasic> {
    const url = `admin/judgehosts`;
    return this.http
      .put<IJudgeHostBasic>(`${environment.API_URL}${url}`, judgehost).pipe(map((judgehostR) => {
        return new JudgeHostBasic(judgehostR);
      }));
  }

  create(judgehost: IJudgeHostBasic): Observable<IJudgeHostBasic> {
    const url = `admin/judgehosts`;
    return this.http
      .post<IJudgeHostBasic>(`${environment.API_URL}${url}`, judgehost).pipe(map((judgehostR) => {
        return new JudgeHostBasic(judgehostR);
      }));
  }

  delete(id: number): Observable<{}> {
    const url = `admin/judgehosts`;
    return this.http.delete(`${`${environment.API_URL}${url}`}/${id}`);
  }

  getJudgeHostById(authorities: Authority[], judgehostId: number): Observable<IJudgeHostBasic> {
    const url = `${this.handleService.getApiRole()}/judgehosts/${judgehostId}`;
    return this.http
      .get<IJudgeHostBasic>(`${environment.API_URL}${url}`).pipe(map((judgehost) => {
        return new JudgeHostBasic(judgehost);
      }));
  }

  getAllJudgingBySumission(submissionId: number): Observable<any[]> {
    const url = `admin/submissions/${submissionId}/judgings/`;
    return this.http
      .get<any[]>(`${environment.API_URL}${url}`).pipe(map((judging) => {
        return judging;
      }));
  }

  getJudgingById(judgingId: number): Observable<any> {
    const url = `admin/judgings/${judgingId}`;
    return this.http
      .get<any>(`${environment.API_URL}${url}`).pipe(map((judging) => {
        return judging;
      }));
  }

  getAllTestsJudgingById(judgingIdd: number): Observable<any[]> {
    const url = `admin/judgings/tests`;
    return this.http
      .get<any[]>(`${environment.API_URL}${url}`, {params:{'judgingId.equals':Number(judgingIdd)}}).pipe(map((tests) => {
        return tests;
      }));
  }




}
