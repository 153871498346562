import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OneColumnLayoutComponent} from "./shared/layout/one-column-layout/one-column-layout.component";
import {NoAuthGuard} from "./core/interceptors/no-auth-guard.service";
import {AuthGuard} from "./core/interceptors/auth-guard.service";
import {Authority} from "./constants/authority.constants";

const routes: Routes = [
  {
    path: '',
    component: OneColumnLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
        canActivate: [NoAuthGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'course',
        loadChildren: () => import('./pages/course/course.module').then(m => m.CourseModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manager',
        loadChildren: () => import('./pages/manager/manager.module').then(m => m.ManagerModule),
        canActivate: [AuthGuard],
        data: {
          authorities: [Authority.ADMIN, Authority.TEACHER]
        },
      },
      {
        path: 'submission',
        loadChildren: () => import('./pages/submission/submission.module').then(m => m.SubmissionModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        data: {
          breadcrumb: {
            info: 'home-outline',
            routeInterceptor: (routeLink, breadcrumb)=> {
              return './dashboard';
            }
          },
          authorities: [Authority.ADMIN]
        },
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
