import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserHeaderConstants} from "../../../../constants/user-header.constants";
import {IUserHeader} from "../../../../models/user-header.model";
import {themeItems, userMenu} from "../../../../constants/admin-header.constants";
import {NbMenuItem, NbSidebarService, NbThemeService} from "@nebular/theme";
import {NbAccessChecker} from "@nebular/security";
import {AccountService} from "../../../../services/account.service";
import {Router} from "@angular/router";
import {ThemesEnum} from "../../../../constants/themes.enum";
import {Subject, takeUntil} from "rxjs";
import {ThemeHandleService} from "../../../../services/theme/theme-handle.service";


@Component({
  selector: 'app-global-sidebar',
  templateUrl: './global-sidebar.component.html',
  styleUrls: ['./global-sidebar.component.scss']
})
export class GlobalSidebarComponent implements OnInit, OnDestroy {
  destroy$: Subject<any>;
  entitiesList = UserHeaderConstants;
  filteredEntitiesList: IUserHeader[] = [];
  themes: NbMenuItem[] | any = themeItems;
  userMenu = userMenu;
  currentTheme: ThemesEnum = ThemesEnum.DARK;

  constructor(
    private accessChecker: NbAccessChecker,
    public accountService: AccountService,
    private sidebarService: NbSidebarService,
    private router: Router,
    private themeService: NbThemeService,
    private themeHandle: ThemeHandleService
  ) {
    this.destroy$ = new Subject();
  }

  ngOnInit(): void {
    this.accountService.accountSubject.subscribe(account => {
      this.filteredEntitiesList = this.entitiesList;
      if (account) {
        this.filteredEntitiesList.forEach(menu => {
          if (menu?.permission && menu?.resource && menu?.auth) {
            this.accessChecker.isGranted(menu.permission, menu.resource).subscribe(hasAccess => {
              menu.hidden = !hasAccess;
            });
          } else {
            menu.hidden = true;
          }
        });
      } else {
        this.entitiesList.forEach(menu => {
          menu.hidden = menu.auth;
        });
      }
    });
    this.themeService.onThemeChange()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName.name;
      });
  }

  trackBy(index: number): number {
    return index;
  }

  goToUrl(menu: IUserHeader): void {
    if (menu?.link) {
      this.router.navigateByUrl(menu.link).then(() => {
        this.collapseSidebar();
      });
    }
  }

  collapseSidebar(): void {
    this.sidebarService.collapse('global-sidebar');
  }

  toggleTheme(): void {
    if (this.currentTheme === ThemesEnum.LIGHT) {
      this.themeHandle.changeTheme(ThemesEnum.DARK);
    } else {
      this.themeHandle.changeTheme(ThemesEnum.LIGHT);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
