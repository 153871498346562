import {NbMenuItem} from '@nebular/theme';

export const adminBaseMenu: NbMenuItem[] = [
  {title: 'Companinha', link: '/admin/affiliation'},
  {title: 'Autor', link: '/admin/author'},
  {title: 'Dificuldade', link: '/admin/difficulty-level'},
  {title: 'FAQ', link: '/admin/faq'},
  {title: 'Habilidades', link: '/admin/skill'},
  {title: 'Tópicos', link: '/admin/topic'},
  {title: 'Disciplinas', link: '/admin/discipline'},
  {title: 'Exercícios', link: '/admin/exercise'},
  {title: 'Medalhas', link: '/admin/medal/dashboard'},
];

export const adminWokMenu: NbMenuItem[] = [
  {title: 'Registros', link: '/admin/registration'},
  {title: 'Módulo', link: '/admin/module'},
  {title: 'Prémios', link: '/admin/reward'},
  {title: 'Pontos', link: '/admin/score'},
  {title: 'Curso', link: '/admin/course'},
  {title: 'Submissões', link: '/admin/submission'},
  {title: 'Submissões (arquivos)', link: '/admin/submission/file'},
  {title: 'Tópicos dos módulos', link: '/admin/module/topic'},
  {title: 'Exercícios', link: '/admin/module/topic/exercise'},
  {title: 'Cénarios dos exercícios', link: '/admin/module/topic/exercise/scenario'},
];

export const adminJudgeMenu: NbMenuItem[] = [
  {title: 'Linguagem', link: '/admin/language'},
  {title: 'Configurações do julgador', link: '/admin/judge/configuration'},
  {title: 'JudgeHost', link: '/admin/judge-host'},
  {title: 'JudgeHost (restrinções)', link: '/admin/judge-host/restriction'},
  {title: 'Habilidades', link: '/admin/internal/error'},
  {title: 'Executable', link: '/admin/executable'},
  {title: 'Rejudging', link: '/admin/rejudging'},
  {title: 'Judging tests', link: '/admin/judging/tests'},
  {title: 'Medalhes', link: '/admin/medal'},
];
export const themeItems = [
  {
    value: 'default',
    title: 'Claro'
  },
  {
    value: 'dark',
    title: 'Escuro',
  }
];
export const userMenu = [{title: 'Perfil', id: 0}, {title: 'Sair', id: 1}];
